import useSWR from "swr"

/**
 * Get the application settings.
 *
 * @export
 * @return {(Veevart.Settings | undefined)}
 */
export function useSettings() : Veevart.Settings | undefined {
    
    const { data }: { data?: Veevart.Settings  } = useSWR(() => {
        return `/veevart/v1/Setting/record/veevart_collection`
    })
    
    return data;
}