import './Spinner.scss';
/**
 * It renders a spinner loading animation.
 *
 * @export
 * @return {*} 
 */
export default function Spinner() {
    return (
        <div className={"loading-wrapper"}>
            <div className="loading">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div> 
        </div>
    )
}