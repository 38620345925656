import Collection from '../components/Collection'
import './Collections.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Layout from '../components/Layout'
import NoData from '../components/NoData';
import { useLabels } from '../hooks/UseLabels';
import { CollectionType } from '../common';
import { useSettings } from '../hooks/UseSetting';

interface RouteParams {
    /**
     * The search value to searching the artworks.
     *
     * @type {string}
     * @memberof RouteParams
     */
    search_string: string
}

interface HomeProps extends RouteComponentProps<RouteParams> { }

/**
 * It render a collection of artworks that can be filter by it artist, objet medium and year. 
 *
 * @export
 * @param {HomeProps} props
 * @return {JSX.Element}  
 */
export function Collections({ match }: HomeProps) {
    
    const settings = useSettings();

    const { labels } = useLabels();

    if (!settings?.enable_collection_section) {
        return (
            <Layout>
                <NoData title={labels.No_results_found} message={labels.It_seems_we_can_t_find_any_results_based_on_your_search} />
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="home">
                <div className="all-collections-title border">
                    <h1>{labels.Our_Collections}</h1>
                </div>
                <section className="home-wrapper">
                    <div className="home-cards-wrapper">
                        <h2 className="structural-heading">Home</h2>
                        <Collection collectionType={CollectionType.Exhibition} pageSize={20} search={match.params.search_string} sortValue="order_in_collection" />
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default withRouter(Collections);