import {useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import * as gtag from '../lib/gtag';
import * as fpixel from '../lib/fpixel';
/**
 * It trigger a new "page view" event using the web analytics tool whenever a new page load.
 *
 * @export
 */
export function usePageViews() {
    let location = useLocation();
    let history = useHistory();
    useEffect(() => {
        if (history.location.pathname === location.pathname) {
            gtag.pageview(location.pathname);
            fpixel.pageview();
        }
    }, [location.pathname, history.location.pathname]);
}