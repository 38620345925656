// const { data: labels, error }: { data?: Veevart.Labels, error?: any } = useSWR(['/veevart/v1/labels']);

import _ from "lodash"
import { useState } from "react"
import useSWR from "swr"
import { useSettings } from "./UseSetting"
const labelsJSON = require('../../labels.json')
const defaultValue: Veevart.Labels = {
    language: 'en_US',
    languages: [],
    labels: labelsJSON
}
export function useLabels() : Veevart.Labels {
    const settings = useSettings();
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const { data }:{data?: Veevart.Labels} = useSWR(() => {
        
        let language = selectedLanguage;
        if (!settings?.multi_languages) {
            language = 'en_US';
        }
        
        if (!language) {
            language = getLanguage();
        }
        
        return `/veevart/v1/labels?language=${language}`
    })

    defaultValue.setSelectedLanguage = setSelectedLanguage;

    return _.merge(defaultValue,data || {});
}

/**
 * Check the navigator language and find the supported one for it. 
 * @returns Returns the supported language to request the labels.
 */
 const getLanguage = () => {

    const langLowerCase: string = (navigator.language || 'en-US').toLowerCase();
    const language = langLowerCase.split('-')[0];
    let lang = langs[langLowerCase];

    if (lang) {
        return lang;
    }

    switch (language) {
        case 'zh': return langs['zh-cn'];
        case 'da': return langs['da'];
        case 'nl': return langs['nl-nl'];
        case 'fi': return langs['fi'];
        case 'fr': return langs['fr'];
        case 'de': return langs['de'];
        case 'it': return langs['it'];
        case 'ja': return langs['ja'];
        case 'ko': return langs['ko'];
        case 'no': return langs['no'];
        case 'pt': return langs['pt-br'];
        case 'ru': return langs['ru'];
        case 'es': return langs['es'];
        case 'sv': return langs['sv'];
        case 'th': return langs['th'];
        default: return langs['en-us'];
    }
}

/**
 * Available navigator languages in the system, another language will be overwritten with one of the supported or the default.
 * @type {*} 
 */
 const langs: Veevart.langs = {
    'zh-cn': 'zh_CN',
    'zh-tw': 'zh_TW',
    'da': 'da',
    'nl-nl': 'nl_NL',
    'en-us': 'en_US',
    'fi': 'fi',
    'fr': 'fr',
    'de': 'de',
    'it': 'it',
    'ja': 'ja',
    'ko': 'ko',
    'no': 'no',
    'pt-br': 'pt_BR',
    'ru': 'ru',
    'es': 'es',
    'es-mx': 'es_MX',
    'sv': 'sv',
    'th': 'th'
}