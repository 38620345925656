/**
 * Trigger the Facebook Pixel event pageview(https://support.google.com/analytics/answer/6086080?hl=en)  
 */
export const pageview = () => {
  if (window.fbq) window.fbq('track', 'PageView')
}

/**
 * Trigger the Facebook Pixel standard event defined.
 * @link https://developers.facebook.com/docs/facebook-pixel/advanced/
 * @param {string} name The standard event name (https://www.facebook.com/business/help/402791146561655?id=1205376682832142)
 * @param {object} options The option to add in the event. 
 */
export const event = (name, options = {}) => {
  if (window.fbq) window.fbq('track', name, options)
}
