/**
 * It check if the field mapping has a match fiel in item and returns it value and friendly label. 
 *
 * @export
 * @param {(Veevart.Artwork | Veevart.Artist)} [item] The artwork/artist to find a match field.
 * @param {Veevart.FieldSetMember[]} [fieldMappings] The field to find in the item.
 * @return {*} 
 */
export function UseFieldValues(item?: Veevart.Artwork | Veevart.Artist | Veevart.Exhibition, fieldMappings?: Veevart.FieldSetMember[]) {
    
    const values:Veevart.FieldValue[] = []

    fieldMappings?.forEach((fieldset, i) => {
        
        if (!item) return null;
        
        let value = item[fieldset.field_path];

        if (fieldset.type === 'CURRENCY') {
            value = Intl.NumberFormat(navigator.language||'en-US',{ style: 'currency', currency: item.currency_ISO || 'USD' }).format(value);
        } 

        if (value === null || value === undefined) return null;
    
        if (fieldset.type === 'MULTIPICKLIST') {
            value = value.replace(/;/g,', ');
        }

        values.push({value,label:fieldset.label, fieldPath: fieldset.field_path,});

    })

    return values;
}