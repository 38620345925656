import { useState } from 'react'
import FilterBar, { FiltersValues } from '../components/FilterBar'
import Collection from '../components/Collection'
import './Search.scss'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import Layout from '../components/Layout'
import { useLabels } from '../hooks/UseLabels';
import { useSettings } from '../hooks/UseSetting';
import { CollectionType } from '../common';

interface RouteParams {
    /**
     * The search value to searching the artworks.
     *
     * @type {string}
     * @memberof RouteParams
     */
    search_string: string
}

interface HomeProps extends RouteComponentProps<RouteParams> { }

/**
 * It render a collection of artworks that can be filter by it artist, objet medium and year. 
 *
 * @export
 * @param {HomeProps} props
 * @return {JSX.Element}  
 */
export function Search({ match }: HomeProps) {

    //The collection sort to apply to the collection. 
    const [sortValue, setSortValue] = useState('Ascending')
    const history = useHistory();

    //The field filters to filter the collection.
    const [filters, setFilters] = useState<VeevartCollection.Filters>({});
    const { labels } = useLabels();
    const [collection, setCollection] = useState(CollectionType.Artwork);
    const settings = useSettings();
    /**
     * Remove a filter or filter value from the filters.
     *
     * @param {string} index The filed name of the filter to be delete.
     * @param {number} valueIndex The position of the filter value to be delete.
     */
    const removeFilterHandler = (index: string, valueIndex?: number) => {
        /** 
         * Clone a the filters.
         */
        let _filters = { ...filters };

        /**
         * Whether the filter should be deleted from the filters.
         */
        let deleted = true;

        if (valueIndex !== undefined) {
            _filters[index].values.splice(valueIndex, 1);
            deleted = !_filters[index].values.length;
        }

        if (deleted) {
            delete _filters[index];
        }

        setFilters(_filters);
    }

    const collections = [
        { label: labels.Artwork, value: CollectionType.Artwork },
        { label: labels.Artist, value: CollectionType.Artist },

    ]

    if (settings?.enable_collection_section) {
        collections.push({ label: labels.Collection, value: CollectionType.Exhibition });
    }

    const sortOptions = [];

    if (collection === CollectionType.Exhibition) {
        sortOptions.push({ label: labels.Relevant, value: "order_in_collection" });
    }

    return (
        <Layout>
            <div className="home">
                <FilterBar isBorder sortOptions={sortOptions} search={match.params.search_string} collectionType={collection} sortValue={sortValue} filters={filters} setFilters={setFilters} onChangeSort={(event) => { setSortValue(event.target.value) }} />
                <div className="filter-search filters-main-wrapper border">
                    <div className="buttons-filter-wrapper">
                        <div dangerouslySetInnerHTML={{ __html: labels.Result_For.replace('{0}', `<b>"${match.params.search_string}"</b>`) }} />
                        <div className="buttons-search">
                            {collections.map((el, i) => (
                                <button key={i} onClick={() => {
                                    setCollection(el.value);
                                    if (el.value === 'Exhibition') {
                                        setSortValue('order_in_collection');
                                    } else {
                                        setSortValue('Ascending');
                                    }
                                    history.push({ search: 'offset=0&pageIndex1' });
                                }} className={`button-filters button-search ${collection === el.value ? 'actived' : ''}`}>{el.label}</button>
                            ))}
                        </div>
                    </div>
                </div>
                {
                    collection === 'Artwork'
                        ? <FiltersValues removeFilter={removeFilterHandler} filters={filters} />
                        : null
                }
                <section className="home-wrapper">
                    <div className="home-cards-wrapper">
                        <h2 className="structural-heading">Home</h2>
                        <Collection pageSize={20} sortValue={sortValue} collectionType={collection} search={match.params.search_string} filters={filters} />
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default withRouter(Search);