import { useSettings } from '../hooks/UseSetting';
import './Footer.scss'
import SelectLanguage from './SelectLanguage';

/**
 * It renders the footer of the collection application
 *
 * @export
 * @return {*} 
 */
export default function Footer() {

    const settings = useSettings();
    const year = (new Date()).getFullYear();
    const socialMedia: string[] | undefined = settings?.social_media?.split(',');
    
    return (
        <footer className="main-footer">
            <div className="main-footer-inner">
                <div className="company-name">©{year} {settings?.name}</div>
                <div className="footer-social-media">
                    {socialMedia ? socialMedia.map((el, i) => {

                        if (!el) return null;

                        const [name, link] = el.split(';');

                        return (
                            <a key={i} target="_blank" rel="noopener noreferrer" href={link} className={`icon-social-media icon-${name}`}>
                                <span>{name}</span>
                            </a>
                        );
                    }) : null}
                </div>
                {settings?.multi_languages
                    ? <SelectLanguage />
                    : null
                }
            </div>
        </footer>
    )
}
