import React from 'react';
import { fetcher } from "../lib/token";
import "./ErrorBoundary.scss";

const ErrorBoundaryContext = React.createContext({});

export const useErrorHandling = () => {
    return React.useContext(ErrorBoundaryContext)
}

/**
 * Components that catch JavaScript errors anywhere in their child component tree, log those errors, and display a fallback UI instead of the component tree that crashed.
 * @link https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
 */
export default class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        errorMessage: "",
    };

    /**
     * @description Set the error message to display a user.
     * @param {Veevart.Exception} error crash javascript error. 
     * @returns 
     */
    static getDerivedStateFromError(error: Veevart.Exception) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
    }

    /**
     * @description Create the frontend error in Salesforce Log object.
     * @param {Veevart.Exception} error crash javascript error. 
     * @param {Veevart.ExceptionInfo} info crash javascript error stack. 
     */
    componentDidCatch(error: Veevart.Exception, info: Veevart.ExceptionInfo) {    
        this.serviceLog(error,info);   
    }

    /**
     * @description Create the frontend error in Salesforce Log object.
     * @param {Veevart.Exception} error crash javascript error. 
     * @param {Veevart.ExceptionInfo} info crash javascript error stack. 
     */
    serviceLog(error: Veevart.Exception, errorInfo: Veevart.ExceptionInfo) {
        // You can also log the error to an error reporting service

        // Service path to log the error in Salesforce. 
        let query = "dl=" + document.location.href + "&dt=" + document.title + '&object=Log';
        let encodeQuery = encodeURI(query);
        let path = "/veevart/collect?" + encodeQuery;

        // The log to send to Salesforce with the stack and message error.
        let logLevel = {
            action: "error_boundary",
            errorMessage: error.message,
            errorStackTrace: errorInfo.componentStack,
            fatal: true,
        };
        let body = JSON.stringify(logLevel);
        let method = "POST";

        fetcher(path,method,body);

    }

    /**
     * @description Create the frontend error in Salesforce Log object. When is trigger for a context (useErrorHandling)
     * @param {Veevart.Exception} error crash javascript error. 
     * @param {Veevart.ExceptionInfo} info crash javascript error stack. 
     */
    triggerError = (error: Veevart.Exception, errorInfo: Veevart.ExceptionInfo) => {
        this.serviceLog(error, errorInfo);
        this.setState({ hasError: true, errorMessage: error.message });
    }

    /**
     * @description Reset the error properties.
     * @returns 
     */
    resetError = () => this.setState({ hasError: false, errorMessage: null });

    render() {
        return (
            <ErrorBoundaryContext.Provider value={{triggerError: this.triggerError}}>
            {this.state.hasError 
                ? <h1 className="error-boundary">Oops, something went wrong</h1>
                : this.props.children
            }
            </ErrorBoundaryContext.Provider>
        );
    }
}
