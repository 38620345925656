import jwt from 'jsonwebtoken'

/**
 * Retrieve the request token. 
 * @param path resource name.
 * @returns {string} token.
 */
export const getToken = (path: string) => {
  
  const token = jwt.sign(
    { subdomain: getSubdomain(), path: path, iat: Math.floor(Date.now() / 1000) }, 
    process.env.REACT_APP_SECRET_KEY || 'shhhh', 
    { expiresIn: 30000  } //30s
  )
  
  return token;
}

/**
 * Retrieve the customer subdomain name. 
 *
 * @return {string} the customer subdomain name. 
 */
const getSubdomain = () => {
  const subdomains = window.location.hostname.split('.')?.reverse()?.slice(2) || ['_veevart_'];
  return subdomains[1];
}

export const fetcher = async (path: string, method: string = 'GET', body?: string) => {
  try {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken(path)}`
      },
      ...(body && { body })
    }

    const response = await fetch(path, options);
    
    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    throw error
  }

}