import { useState } from 'react'
import FilterBar, { FiltersValues } from '../components/FilterBar'
import Collection from '../components/Collection'
import './Collection.scss'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import Layout from '../components/Layout'
import { useLabels } from '../hooks/UseLabels';
import useSWR from 'swr';
import NoData from '../components/NoData';
import Loading from '../components/Spinner'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { CollectionType } from '../common';
import { useSettings } from '../hooks/UseSetting';
interface RouteParams {
    /**
     * The search value to searching the artworks.
     *
     * @type {string}
     * @memberof RouteParams
     */
    collection: string
}

interface HomeProps extends RouteComponentProps<RouteParams> { }

/**
 * It render a collection of artworks that can be filter by it artist, objet medium and year. 
 *
 * @export
 * @param {HomeProps} props
 * @return {JSX.Element}  
 */
export function CollectionView({ match }: HomeProps) {
    
    //The collection sort to apply to the collection. 
    const [sortValue, setSortValue] = useState('relevant')
    const history = useHistory();

    //The field filters to filter the collection.
    const [filters, setFilters] = useState<VeevartCollection.Filters>({});
    const { labels } = useLabels();
    const [toggleReadText, setToggleReadText] = useState(true);
    const [collection, setCollection] = useState(CollectionType.Artwork);
    const settings = useSettings();
    const { data, error }: { data?: Veevart.Exhibition, error?: any } = useSWR([`/veevart/v1/Exhibition/record/${match.params.collection}`])

    /**
     * Remove a filter or filter value from the filters.
     *
     * @param {string} index The filed name of the filter to be delete.
     * @param {number} valueIndex The position of the filter value to be delete.
     */
    const removeFilterHandler = (index: string, valueIndex?: number) => {
        /** 
         * Clone a the filters.
         */
        let _filters = { ...filters };

        /**
         * Whether the filter should be deleted from the filters.
         */
        let deleted = true;

        if (valueIndex !== undefined) {
            _filters[index].values.splice(valueIndex, 1);
            deleted = !_filters[index].values.length;
        }

        if (deleted) {
            delete _filters[index];
        }

        setFilters(_filters);
    }

    const collections = [
        { label: labels.Artwork, value: CollectionType.Artwork },
        { label: labels.Artist, value: CollectionType.Artist }
    ]

    const sortOptions = [];

    if (collection === CollectionType.Artwork) {
        sortOptions.push({ label: labels.Relevant, value: "relevant" });
    }

    /** It render a UI message when somethin went wrong in the API Call */
    if (error) return <NoData title={labels.No_results_found} message={labels.Error_Loading_PDF} />

    /** It render a UI loading when change the page/fetch data. */
    if (!data) return <Loading />

    /** It render a UI no found when the artist is not exist */
    if (data.status === 404 || !settings?.enable_collection_section) {
        return (
            <Layout>
                <NoData title={labels.No_results_found} message={labels.It_seems_we_can_t_find_any_results_based_on_your_search} />
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="collection">
                {
                    data.showImage
                        ? <figure className="banner">
                            <img src={data.PrimaryFile} alt={data.Name} />
                        </figure> : null
                }

                <div className="collection-title-wrapper">
                    <div className="collection-title">
                        <h1>{data.Name}</h1>
                    </div>
                    
                
                    {
                        data.Description && (
                            <div className="wrapper-description">

                                {
                                    toggleReadText ? (
                                        <>
                                            <HTMLEllipsis
                                                unsafeHTML={data.Description}
                                                maxLine='3'
                                                // trimRight={false}
                                                ellipsis='...'
                                                onReflow={({ html }: { html:any }) => {
                                                    if (html.length >= data.Description.length) {
                                                        setToggleReadText(false);
                                                    }
                                                }}
                                            />
                                            <button className="button-read-toggle" onClick={() => setToggleReadText(false)}>{labels.View_More_Info}</button>
                                        </>
                                    ) : <>
                                        <div dangerouslySetInnerHTML={{ __html: data.Description }} />
                                        <button className="button-read-toggle" onClick={() => setToggleReadText(true)}>{labels.View_Less_Info}</button>
                                    </>
                                }
                            </div>
                        )
                    }
                </div>


                <div className="filter-search filters-main-wrapper border">
                    <div className="buttons-filter-wrapper">
                        <div></div>
                        <div className="buttons-search">
                            {collections.map((el, i) => (
                                <button key={i} onClick={() => {
                                    setCollection(el.value);
                                    history.push({ search: 'offset=0&pageIndex1' });
                                }} className={`button-filters button-search ${collection === el.value ? 'actived' : ''}`}>{el.label}</button>
                            ))}
                        </div>
                    </div>
                </div>

                <FilterBar exhibition={data.id} sortOptions={sortOptions} collectionType={collection} sortValue={sortValue} filters={filters} setFilters={setFilters} onChangeSort={(event) => { setSortValue(event.target.value) }} />

                {
                    collection === CollectionType.Artwork
                        ? <FiltersValues removeFilter={removeFilterHandler} filters={filters} />
                        : null
                }

                <section className="home-wrapper">
                    <div className="home-cards-wrapper">
                        <h2 className="structural-heading">Home</h2>
                        <Collection pageSize={20} exhibition={data.id} sortValue={sortValue} collectionType={collection} filters={filters} />
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default withRouter(CollectionView);