import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import {useState} from 'react'
import 'react-image-lightbox/style.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

type GalleryProps = {
    /**
     * List of images to render.
     */
    files?: Veevart.File[]
}
/**
 * It renders the Gallery Images
 *
 * @export
 * @param {GalleryProps} props
 * @return {*} 
 */
export default function Gallery({ files }: GalleryProps) {

    const [selectedImage, setSelectedImage] = useState(0)
    const [showLightBox, setShowLightBox] = useState(false)
    
    const carouselImages:JSX.Element[] = []
    const lightboxImages:string[] = []

    files?.forEach((file,i) => {
        lightboxImages.push(file.Link)
        carouselImages.push(<CarouselImage key={i} link={file.Link} handleClick={() => {
            setSelectedImage(i)
            setShowLightBox(c => !c)
        }}/>)
    })

    return (
        <div className="carousel-box">
            <Carousel
                dynamicHeight={true}
                axis="horizontal"
                showThumbs={false}
                showArrows={true}
                showStatus={false}
                emulateTouch={false}
                showIndicators={true}
                selectedItem={selectedImage}
                transitionTime={0}
            >
                {carouselImages}
            </Carousel>

            {showLightBox && (
                <Lightbox
                    mainSrc={lightboxImages[selectedImage]}
                    nextSrc={lightboxImages[(selectedImage + 1) % lightboxImages.length]}
                    prevSrc={lightboxImages[(selectedImage + lightboxImages.length - 1) % lightboxImages.length]}
                    onCloseRequest={() => setShowLightBox(c => !c)}
                    onMovePrevRequest={() => setSelectedImage((selectedImage + lightboxImages.length - 1) % lightboxImages.length )}
                    onMoveNextRequest={() => setSelectedImage((selectedImage + 1) % lightboxImages.length)}
                />
            )} 
        </div>
    )
}

type CarouselImageProps = {
    /**
     * Method that opens the lightbox. 
     *
     */
    handleClick: () => void;
    /**
     * The image link.
     *
     * @type {string}
     */
    link: string;
}
/**
 * It renders the carouse images
 *
 * @param {CarouselImageProps} props
 * @return {*} 
 */
function CarouselImage({ handleClick, link }: CarouselImageProps) {
    return (
        <figure className='item-image-wrapper' onClick={handleClick} >
            <img
                alt="gallery-item"
                className='item-image'
                src={link}
            />
        </figure>
    )
}