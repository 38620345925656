let urlSent = ''; //Save the last url sent
let pathSent = ''; //Save the last path sent

/**
 * Trigger the Google Analytics event pageview(https://support.google.com/analytics/answer/6086080?hl=en)
 * @link https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @link https://developers.google.com/analytics/devguides/migration/measurement/virtual-pageviews?hl=es-419
 * @param {string} url The page path to track.
 */
export const pageview = (url, path = null) => {
    if (window.gtag && urlSent !== url && pathSent !== path) {
        urlSent = url;
        pathSent = path;
        window.gtag('event', 'page_view', {
            page_location: url,
            page_path: path,
        });
    }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    if (window.gtag)
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        });
};
