import { useLabels } from "../hooks/UseLabels";
import Select from "./Select";

/**
 * Select a language 
 *
 * @export
 * @return {*} Select with the languages supported.
 */
export default function SelectLanguage() {

    const {languages, setSelectedLanguage, language, labels} = useLabels();
    
    /**
     * Refresh the labels when the language changes. 
     */
     const onHandleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (value !== language && setSelectedLanguage) {
            setSelectedLanguage(value);
        }
    }

    if (!languages.length || language == null || !setSelectedLanguage) {
        return null;
    }

    return (
        <div className="veevart-form-element">
            <Select inline label={labels.Language} onChange={onHandleChange} options={languages} value={language} />
        </div>
    )
}