import React, { useState } from 'react'
import './Search.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';
import { useLabels } from '../hooks/UseLabels';

interface SearchProps extends RouteComponentProps {
    /**
     * Method to handle the search submit event.
     *
     * @param {React.FormEvent<HTMLFormElement>} event
     * @param {string} searchValue
     * @memberof Props
     */
    submithHandler?(event: React.FormEvent<HTMLFormElement>, searchValue: string): void;
    /**
     * Search default value.
     *
     * @type {string}
     * @memberof SearchProps
     */
    defaultVal?: string;
    /**
     * Whether to display the search bar.
     *
     * @type {boolean}
     * @memberof SearchProps
     */
    showSearchBarResponsive?: boolean;
}

/**
 * It render a search bar.
 *
 * @export
 * @param {SearchProps} props
 * @return {*} 
 */
export function Search({ history, submithHandler, defaultVal, showSearchBarResponsive }: SearchProps) {

    let [searchVal, setSearchVal] = useState('');

    // The application labels
    const {labels} = useLabels();

    //set a default value to the search bar.     
    useEffect(() => {
        if (defaultVal || defaultVal === '') setSearchVal(defaultVal);
    }, [defaultVal])

    /**
     * It push a new route or call a custom handle. 
     *
     * @param {React.FormEvent<HTMLFormElement>} event
     */
    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (submithHandler) {
            submithHandler(event, searchVal);
        } else {
            history.push('/search/' + encodeURIComponent(searchVal));
        }
    }

    /**
     * It change the search value with the target value.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setSearchVal(event.target.value);
    }

    return (
        <div className={`search-container ${showSearchBarResponsive !== undefined ? showSearchBarResponsive : ''}`}>
            <div className='search-container-inner'>
                <form className='search-form' onSubmit={onSubmitHandler}>
                    <div className='search-input-wrapper'>
                        <span className='search-icon'></span>
                        <input
                            placeholder={labels.Search_Something}
                            className='input-search'
                            type='search'
                            id='mySearch'
                            name='search'
                            autoComplete='off'
                            value={searchVal}
                            onChange={onChangeHandler}
                            autoFocus={false}
                        />

                        <button type="submit" className="button-search">{labels.Search}</button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default withRouter(Search);