import { Link } from 'react-router-dom'
import Lazyload from 'react-lazyload'
import { UseFieldValues } from '../hooks/UseFieldValues';
import './Card.scss'
import { CollectionType } from '../common';
import CardDetails from "./CardDetails";

type CardProps = {
    /**
     * The artwork to render
     *
     * @type {Veevart.Artwork}
     */
    record: Veevart.Artwork | Veevart.Artist | Veevart.Exhibition;
    /**
     * The artwork mapping fields to display in the render.
     *
     * @type {Veevart.FieldSetMember[]}
     */
    fieldMappings: Veevart.FieldSetMember[];

    /**
     * The Collection type e.g. "Artwork"
     *
     * @type {CollectionType | undefined}
     */
    collectionType?: CollectionType | undefined;


    /**
     * The Settings Collection
     *
     * @type {settingsCollection}
     */
     settingsCollection?: Veevart.Settings | undefined;
}

/**
 * Render the artwork presentation view with it details.
 *
 * @export
 * @param {CardProps} 
 * @return {*} 
 */
export default function Card({ record, fieldMappings, collectionType, settingsCollection }: CardProps) {

    const fieldValues = UseFieldValues(record, fieldMappings);
    if (collectionType === CollectionType.Artist) {
        return <CardArtist artist={record as Veevart.Artist} />;
    } if (collectionType === CollectionType.Exhibition) {
        return <CardExhibition exhibition={record as Veevart.Exhibition} />;
    } else {
        return <CardArtwork artwork={record as Veevart.Artwork} fieldValues={fieldValues} settingsCollection={settingsCollection}/>;
    }

}

/**
 * Artwork collection card 
 *
 * @param {{
 *     artwork: Veevart.Artwork;
 *     fieldValues: Veevart.FieldValue[]
 *     settingsCollection: Veevart.Settings
 * }} { artwork, fieldValues, settingsCollection }
 * @return {*} 
 */
function CardArtwork({ artwork, fieldValues, settingsCollection }: {
    /**
     * Artwork information 
     *
     * @type {Veevart.Artwork}
     */
    artwork: Veevart.Artwork;
    /**
     * The field mapping values 
     *
     * @type {Veevart.FieldValue[]}
     */
    fieldValues: Veevart.FieldValue[]

    settingsCollection: Veevart.Settings | undefined;
}) {
  const titleField = settingsCollection?.title_card || "Name";

  const hideCardTitle = settingsCollection?.hide_card_title == 1 

    return (
        <Lazyload height={200} throttle={100}>
            <div className='item-wrapper'>
                <figure
                    className='item-image-wrapper'>
                    <Link to={`/artists/${artwork.artist_unique_name}/artworks/${artwork.UniqueName}`}>
                        {
                            artwork.PrimaryFile &&
                            <img
                                className='item-image'
                                src={artwork.PrimaryFile}
                                alt={artwork.Name}
                            />
                        }
                    </Link>

                </figure>

                <div className='item-info'>
                    {!hideCardTitle ? 
                    <div className="item-title-wrapper">
                        <h3 className="item-title">
                        <Link
                            to={`/artists/${artwork.artist_unique_name}/artworks/${artwork.UniqueName}`}
                        >
                            {artwork[titleField]}
                        </Link>
                        </h3>  
                    </div> : null}
                
                    {hideCardTitle ? 
                    <Link to= {`/artists/${artwork.artist_unique_name}/artworks/${artwork.UniqueName}`}>
                      <CardDetails 
                        settingsCollection={settingsCollection}
                        fieldValues={fieldValues}
                        />
                    </Link> : 
                    <CardDetails 
                        settingsCollection={settingsCollection}
                        fieldValues={fieldValues}
                    />}
              </div>
            </div>
        </Lazyload>
    )
}

/**
 * Collection artist card. 
 *
 * @param {{
 *     artist: Veevart.Artist;
 * }} { artist }
 * @return {*} 
 */
function CardArtist({ artist }: {
    /**
     * The artist inforamtion.
     *
     * @type {Veevart.Artist}
     */
    artist: Veevart.Artist;
}) {
    return (
        <Lazyload height={200} throttle={100}>
            <div className='item-wrapper'>
                <figure
                    className='item-image-wrapper'>
                    <Link to={`/artists/${artist.artist_unique_name}`}>
                        {
                            artist.artist_photo &&
                            <img
                                className='item-image'
                                src={artist.artist_photo}
                                alt={artist.Name}
                            />
                        }
                    </Link>

                </figure>

                <div className='item-info'>
                    <div className="item-title-wrapper">
                        <h3 className='item-title'>
                            <Link to={`/artists/${artist.artist_unique_name}`}>
                                {artist.Name}
                            </Link>
                        </h3>
                    </div>
                    <div className="other-info">
                        {
                            artist.Nationality ? <div>{artist.Nationality}</div> : null
                        }
                    </div>
                </div>
            </div>
        </Lazyload>
    )
}



function CardExhibition({ exhibition }: {
    /**
     * The exhibition inforamtion.
     *
     * @type {Veevart.Exhibition}
     */
     exhibition: Veevart.Exhibition;
}) {
    return (
        <Lazyload height={200} throttle={100}>
            <div className='item-wrapper'>
                <figure
                    className='item-image-wrapper'>
                    <Link to={`/collections/${exhibition.UniqueName}`}>
                        {
                            exhibition.PrimaryFile &&
                            <img
                                className='item-image'
                                src={exhibition.PrimaryFile}
                                alt={exhibition.Name}
                            />
                        }
                    </Link>

                </figure>

                <div className='item-info'>
                    <div className="item-title-wrapper">
                        <h3 className='item-title'>
                            <Link to={`/collections/${exhibition.UniqueName}`}>
                                {exhibition.Name}
                            </Link>
                        </h3>
                    </div>
                    <div className="other-info">
                        {
                            exhibition.Description ? <div className="text_ellipsis" dangerouslySetInnerHTML={{__html:exhibition.Description}}/> : null
                        }
                    </div>
                </div>
            </div>
        </Lazyload>
    )
}

