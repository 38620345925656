import './Card.scss'

type CardDetailsProps = {
    /**
     * The Settings Collection
     *
     * @type {settingsCollection}
     */
     settingsCollection?: Veevart.Settings | undefined;
       /**
     * The field mapping values 
     *
     * @type {Veevart.FieldValue[]}
     */
    fieldValues: Veevart.FieldValue[]
}

export default function CardDetails({settingsCollection, fieldValues} : CardDetailsProps) {

    const boldFields = settingsCollection?.bold_home ? settingsCollection?.bold_home
    .replaceAll(" ", "")
    .split(",") : "";

  const italicFields = settingsCollection?.italic_home ? settingsCollection?.italic_home
    .replaceAll(" ", "")
    .split(",") : "";


    return (
        <div className="other-info">
        {fieldValues.map((el: Veevart.FieldValue, i: number) => (
            <div className="field" key={i}>
            {boldFields &&
            boldFields.includes(el.fieldPath) &&
            italicFields &&
            italicFields.includes(el.fieldPath) ? (
                <i>
                <b>{el.value}</b>
                </i>
            ) : boldFields && boldFields.includes(el.fieldPath) ? (
                <b>{el.value}</b>
            ) : italicFields && italicFields.includes(el.fieldPath) ? (
                <i>{el.value}</i>
            ) : (
                el.value
            )}
            </div>
        ))}
        </div>
    )
}