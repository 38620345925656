import './NoData.scss'

type NoDataProps = {
    /**
     * The friendly message to display to users
     *
     * @type {string}
     */
    message?: string,
    /**
     * The fiendly title to display to users.
     *
     * @type {string}
     */
    title?: string,
}

/**
 * It renders the message no data/results found.
 *
 * @export
 * @param {NoDataProps} props
 * @return {*} 
 */
export default function NoData({message, title}: NoDataProps) {
    
    return (
        <div className="no-results-wrapper">
            <div className="message-no-data">
                <div className="no-data-inner">
                    {title && <div className="message-h1">{title}</div>}
                    <div className="message-title">{message || 'No results found'}</div>
                </div>
            </div>
        </div>
    )
}