import React from 'react'
import Header from './Header'
import Footer from './Footer'
import './Layout.scss'
type LayoutProps = {
    /**
     * The children components injected in the layout
     *
     * @type {React.ReactChild}
     */
    children: React.ReactChild
}

/**
 * It renders the collection application header, body and footer
 *
 * @export
 * @param {LayoutProps} props
 * @return Render the collection Layout with it header, footer, and main 
 */
export default function Layout({children} : LayoutProps) {
    return (
        <div>
            <Header/>
            <main className="wrapper-general">{children}</main>
            <Footer/>
        </div>
    )
}