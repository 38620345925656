import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import 'rc-pagination/assets/index.css'
import { SWRConfig } from 'swr'
import { getToken } from './lib/token';
import ErrorBoundary from './components/ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum';

if (window.location.href.includes('veevartapp')) {
    datadogRum.init({
        applicationId: '64865fb7-b06b-4f51-af4c-8d40f761dbb4',
        clientToken: 'pub9db52e2a54927a0f637320cce7ee13a8',
        site: 'datadoghq.com',
        service: 'online-apps-collection',
        env: 'prod',
        version: process.env.npm_package_version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
    
    datadogRum.startSessionReplayRecording();
    
}

ReactDOM.render(
	<ErrorBoundary>
		<SWRConfig
			value={{
				shouldRetryOnError: false,
				/**
				 * Retrive the data fetch it in the API. 
				 * @param {string} path The resource to request.
				 * @param {string} [method=GET] The method to use in the request. 
				 * @param {string} [body] The body required in some request.
				 * @returns 
				 */
				fetcher: async (path: string, method: string = 'GET', body?: string) => {
					try {
						const options = {
							method,
							headers: {
								'Content-Type': 'application/json',
								'Authorization': `Bearer ${getToken(path)}`
							},
							...(body && { body })
						}

						const response = await fetch(path, options);

						if (!response.ok) {
							const message = `An error has occured: ${response.status}`;
							throw new Error(message);
						}

						const data = await response.json();
						return data;

					} catch (error) {
						throw error
					}

				}
			}}
		>
			<React.StrictMode>
				<Router>
					<App />
				</Router>
			</React.StrictMode>
		</SWRConfig>
	</ErrorBoundary>,
	document.getElementById('app-root')
);
