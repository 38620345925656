import React, { useState } from 'react';
import Select from './Select';
import ReactDOM from 'react-dom';
import useSWR from 'swr';
import './Filters.scss';
import { useLabels } from '../hooks/UseLabels';
import { useHistory } from 'react-router-dom';
import { useSettings } from '../hooks/UseSetting';
import { CollectionType, FilterTemplate } from '../common';
import AsyncSelect, { OptionsOrGroups, StylesConfig } from 'react-select';

/**
 * It renders the filter bar and a filter modal when clicked on "filters".
 *
 * @export
 * @param {FilterBar}
 * @return {*}
 */
export default function FilterBar({
    onChangeSort,
    sortValue,
    filters,
    setFilters,
    isBorder,
    collectionType,
    artist,
    exhibition,
    search,
    sortOptions = [],
}: {
    /**
     * Method to change the sort value.
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event
     */
    onChangeSort(event: React.ChangeEvent<HTMLSelectElement>): void;

    /**
     * Sort use for set default selected sord.
     *
     * @type {string}
     */
    sortValue: string;

    /**
     * Metho to change the selected filters.
     *
     * @type {React.Dispatch<React.SetStateAction<VeevartCollection.Filters>>}
     */
    setFilters: React.Dispatch<React.SetStateAction<VeevartCollection.Filters>>;

    /**
     * The field filters to filter the collection.
     *
     * @type {VeevartCollection.Filters}
     */
    filters: VeevartCollection.Filters;

    /**
     * Whether the filter bar have a underline in the bottom.
     *
     * @type {boolean}
     */
    isBorder?: boolean;

    /**
     * The Collection type e.g. "Artwork"
     *
     * @type {string}
     */
    collectionType?: CollectionType | undefined;

    /**
     * the artist to filter the artworks.
     *
     * @type {string}
     */
    artist?: string;

    /**
     * the exhibition to filter the artworks.
     *
     * @type {string}
     */
    exhibition?: string;

    /**
     * The search value to filter the artworks.
     *
     * @type {string}
     */
    search?: string;

    sortOptions?: { label: string; value: string }[];
}) {
    //Whether the filters modal should be displayed.
    const [displayFilters, setDisplayFilters] = useState(false);
    const settings = useSettings();
    // The application labels
    const { labels } = useLabels();

    const ufilters: { label: string; field: string }[] = [
        { label: labels.Artist_Name, field: 'artist_name' },
        { label: labels.Category, field: 'ObjectMedium' },
        { label: labels.Year, field: 'YearOfWork' },
    ];

    const _sortOptions = [];

    if (collectionType === 'Artwork') {
        _sortOptions.push(
            ...[
                {
                    label: labels.Title_Name_A_Z,
                    value: 'ASC;Name',
                },
                {
                    label: labels.Title_Name_Z_A,
                    value: 'DESC;Name',
                },
                {
                    label: labels.Artist_Name_A_Z,
                    value: 'ASC;artist_name',
                },
                {
                    label: labels.Artist_Name_Z_A,
                    value: 'DESC;artist_name',
                },
                {
                    label: labels.Year_Newest_Oldest,
                    value: 'DESC;YearOfWork',
                },
                {
                    label: labels.Year_Oldest_Newest,
                    value: 'ASC;YearOfWork',
                },
            ]
        );
    }

    if (collectionType === 'Artist') {
        _sortOptions.push(
            ...[
                {
                    label: labels.Artist_Name_A_Z,
                    value: 'ASC;artist_name',
                },
                {
                    label: labels.Artist_Name_Z_A,
                    value: 'DESC;artist_name',
                },
            ]
        );
    }

    if (collectionType === 'Exhibition') {
        _sortOptions.push(
            ...[
                {
                    label: labels.Collection_Name_A_Z,
                    value: 'ASC;Name',
                },
                {
                    label: labels.Collection_Name_Z_A,
                    value: 'DESC;Name',
                },
            ]
        );
    }

    _sortOptions.push(...sortOptions);

    if (settings?.collection_filter_template === FilterTemplate.SpaceBetween) {
        return (
            <div className="filters-main-wrapper">
                {collectionType === CollectionType.Artwork ? (
                    <>
                        <div className="filter-label">{labels.Filter_By + ':'}</div>
                        <div className="buttons-filter-wrapper border">
                            {ufilters.map((ufilter, i) => {
                                let vals = labels.None;

                                if (filters[ufilter.field]?.values?.length) {
                                    vals = filters[ufilter.field].values.toString();
                                }

                                return (
                                    <button
                                        key={i}
                                        className={'button-filters'}
                                        onClick={() => {
                                            setDisplayFilters((el) => !el);
                                        }}
                                    >
                                        <Select
                                            value={vals}
                                            options={[{ label: vals, value: vals }]}
                                            label={ufilter.label}
                                            onChange={() => {}}
                                        />
                                    </button>
                                );
                            })}
                        </div>
                    </>
                ) : null}

                <div className="button-filter-wrapper">
                    <div></div>
                    <div className="select-sort-wrapper">
                        <Select
                            onChange={onChangeSort}
                            label={labels.Sort_Order}
                            value={sortValue}
                            options={_sortOptions}
                        />
                    </div>
                </div>
                {displayFilters && (
                    <Filters
                        artist={artist}
                        exhibition={exhibition}
                        search={search}
                        labels={labels}
                        filters={filters}
                        setFilters={setFilters}
                    />
                )}
            </div>
        );
    } else {
        return (
            <div className={`filters-main-wrapper ${isBorder ? 'border' : ''}`}>
                <div className="button-filter-wrapper">
                    {collectionType === CollectionType.Artwork ? (
                        <button className={'button-filters'} onClick={() => setDisplayFilters((el) => !el)}>
                            {labels.Filters}
                        </button>
                    ) : (
                        <div></div>
                    )}
                    <div className="select-sort-wrapper">
                        <Select
                            onChange={onChangeSort}
                            label={labels.Sort_Order}
                            value={sortValue}
                            options={_sortOptions}
                        />
                    </div>
                </div>
                {displayFilters && (
                    <Filters
                        artist={artist}
                        exhibition={exhibition}
                        search={search}
                        labels={labels}
                        filters={filters}
                        setFilters={setFilters}
                    />
                )}
            </div>
        );
    }
}

/**
 * It renders a pop-up with the filters available for the collection.
 *
 * @param param0
 * @returns
 */
export function Filters({ setFilters, filters, labels, artist, exhibition, search }: VeevartCollection.FiltersProps) {
    const { data }: { data?: Veevart.Filters; error?: any } = useSWR(() => {
        let url = `/veevart/v1/Artwork/filters?`;

        if (artist) {
            url += `&artist=${artist}`;
        }

        if (exhibition) {
            url += `&exhibition=${exhibition}`;
        }

        if (search) {
            url += `&search=${encodeURIComponent(search)}`;
        }

        return [url];
    });

    return (
        <div>
            <section className="filter">
                <label>Add New Filter</label>
                <div className="filter-selects">
                    <MultiSelect
                        data={data}
                        category="artist_name"
                        setFilters={setFilters}
                        vLabel={labels.Artist_Name}
                        selectedFilters={filters.artist_name?.values}
                    />
                    <MultiSelect
                        data={data}
                        category="ObjectMedium"
                        setFilters={setFilters}
                        vLabel={labels.Category}
                        selectedFilters={filters.ObjectMedium?.values}
                    />
                    <MultiSelect
                        data={data}
                        category="YearOfWork"
                        setFilters={setFilters}
                        vLabel={labels.Year}
                        selectedFilters={filters.YearOfWork?.values}
                    />
                </div>
            </section>
        </div>
    );
}

/**
 * Multi Select Component
 * @param props {VeevartCollection.MultiSelectProps}
 * @returns
 */
const MultiSelect = ({ data, category, setFilters, vLabel, selectedFilters }: VeevartCollection.MultiSelectProps) => {
    const values = selectedFilters?.map((item) => ({ value: item, label: item })) || [];
    const options: OptionsOrGroups<VeevartCollection.MultiSelectOption, any> =
        data?.[category]?.map((item) => ({ ...item, label: item.value })) || [];
    const vOnChange = (option: any) => {
        if (option.length) {
            setFilters((item = {}) => {
                return {
                    ...item,
                    [category]: {
                        values: option.map((item: VeevartCollection.MultiSelectOption) => item.value),
                        label: vLabel,
                    },
                };
            });
        }
    };

    const vStyles: StylesConfig<VeevartCollection.MultiSelectOption> = {
        multiValue: () => ({ display: 'none' }),
        input: (style: any, state: any) => {
            return {
                ':after': {
                    content: `"${state.value ? '' : vLabel}"`,
                    position: 'absolute',
                    left: 0,
                    paddingLeft: 10,
                    color: '#7f8080',
                },
            };
        },
    };

    return (
        <AsyncSelect
            isMulti
            value={values}
            name="artist_name"
            isClearable={false}
            onChange={vOnChange}
            options={options}
            className="basic-multi-select"
            placeholder={false}
            classNamePrefix="select"
            styles={vStyles}
        />
    );
};

/**
 * It render the child component in other div in the HTML.
 *
 * @export
 * @param {{ children: React.ReactNode }} { children }
 * @return {*}
 */
export function Portal({ children }: { children: React.ReactNode }) {
    const modalRoot = document.getElementById('modal-root');

    if (!modalRoot) return null;

    return ReactDOM.createPortal(children, modalRoot);
}

/**
 * Render list of filters with it values.
 *
 * @param {FiltersProps} props
 * @return {*}
 */
export function FiltersValues({
    removeFilter,
    filters,
}: {
    /**
     * Method to remove a filter from the filters.
     *
     * @param {string} index The field name of the filter to be deleted.
     * @param {number} valueIndex The position of the filter value to be delete.
     */
    removeFilter(index: string, valueIndex?: number): void;
    /**
     * The field filters to filter the collection.
     *
     * @type {VeevartCollection.Filters}
     */
    filters: VeevartCollection.Filters;
}) {
    const selectedFilters: JSX.Element[] = [];

    for (const index in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, index)) {
            const filter = filters[index];
            selectedFilters.push(
                <div className="filters__option" key={index}>
                    <div className="filters__option__label">
                        <button className="button-remove-value" onClick={() => removeFilter(index)} />
                        <label>{filter.label}:</label>
                    </div>
                    <div className="filters__option__values">
                        {filter.values.map((value, valueIndex) => (
                            <div className="filters__option__values__value" key={valueIndex}>
                                <div className="value-selected-inner">
                                    <div className="value-selected">{value}</div>
                                    <button
                                        className="button-remove-value"
                                        onClick={() => removeFilter(index, valueIndex)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    }

    return <div className="filters">{selectedFilters}</div>;
}
