import { useState } from 'react'
import FilterBar, {FiltersValues} from '../components/FilterBar'
import Collection from '../components/Collection'
import './Home.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Layout from '../components/Layout'
import { CollectionType } from '../common';

interface RouteParams {
    /**
     * The search value to searching the artworks.
     *
     * @type {string}
     * @memberof RouteParams
     */
    search_string: string
}

interface HomeProps extends RouteComponentProps<RouteParams> { }

/**
 * It render a collection of artworks that can be filter by it artist, objet medium and year. 
 *
 * @export
 * @param {HomeProps} props
 * @return {JSX.Element}  
 */
export function Artworks({ match }: HomeProps) {

    //The collection sort to apply to the collection. 
    const [sortValue, setSortValue] = useState('Ascending')

    //The field filters to filter the collection.
    const [filters, setFilters] = useState<VeevartCollection.Filters>({});

    /**
     * Remove a filter or filter value from the filters.
     *
     * @param {string} index The filed name of the filter to be delete.
     * @param {number} valueIndex The position of the filter value to be delete.
     */
    const removeFilterHandler = (index: string, valueIndex?: number) => {
        /** 
         * Clone a the filters.
         */
        let _filters = { ...filters };

        /**
         * Whether the filter should be deleted from the filters.
         */
        let deleted = true;

        if (valueIndex !== undefined) {
            _filters[index].values.splice(valueIndex, 1);
            deleted = !_filters[index].values.length;
        }

        if (deleted) {
            delete _filters[index];
        }

        setFilters(_filters);
    }

    return (
        <Layout>
            <div className="home">
                <FilterBar  collectionType={CollectionType.Artwork} isBorder sortValue={sortValue} filters={filters} setFilters={setFilters} onChangeSort={(event) => { setSortValue(event.target.value) }} />
                <FiltersValues removeFilter={removeFilterHandler} filters={filters} />
                <section className="home-wrapper">
                    <div className="home-cards-wrapper">
                        <h2 className="structural-heading">Home</h2>
                        <Collection pageSize={20} sortValue={sortValue} search={match.params.search_string} filters={filters} />
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default withRouter(Artworks);