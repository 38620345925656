import './Select.scss'

type SelectProps = {
    /**
     * The selected label to display to users.
     *
     * @type {string}
     */
    label: string,
    /**
     * Method to change the sort picklist.
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event
     */
    onChange(event: React.ChangeEvent<HTMLSelectElement>): void,
    /**
     * The list of options to render in the select.
     *
     * @type {(string[] | Option[])}
     */
    options: string[] | Option[]
    /**
     * The selected value.
     *
     * @type {string}
     */
    value: string
     /**
     * Whether the label is inline or not.
     */
      inline?: boolean;
}

/**
 * The select options.
 */
type Option = {
    /**
     * The label of the option.
     *
     * @type {string}
     */
    label: string,
    /**
     * The value of the option.
     *
     * @type {string}
     */
    value: string;
}

/**
 * It render a select. 
 *
 * @export
 * @param {SelectProps} props
 * @return {*} 
 */
export default function Select({ label, onChange, options, value, inline }: SelectProps) {

    const selectOptions: JSX.Element[] = [];

    options.forEach((option: string | Option, index: number) => {
        if (typeof option === 'string') {
            selectOptions.push(<option value={option} key={index}>{option}</option>)
        } else {
            selectOptions.push(<option value={option.value} key={index}>{option.label}</option>)
        }
    }
    )


    return (
        <div className={"select-wrapper" + (inline ? ' select-inline ' : '')}>
            <label className="select-label">{label}</label>
            <div className="select-container">
                <select onChange={onChange} value={value} className="select-input">
                    {selectOptions}
                </select>
            </div>
        </div>
    )
}