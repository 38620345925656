import { UseFieldValues } from '../hooks/UseFieldValues';
import { useSettings } from "../hooks/UseSetting";
import Spinner from './Spinner';

type DetailsProps = {
    /**
     * The artwork/artists information.
     *
     * @type {(Veevart.Artwork | Veevart.Artist)}
     */
    item: Veevart.Artwork | Veevart.Artist,
    /**
     * The field mapping.
     *
     * @type {Veevart.FieldSetMember[]}
     */
    fieldMappings?: Veevart.FieldSetMember[]
}

/**
 * It renders the artwork/artist details information.
 *
 * @export
 * @param {DetailsProps} props
 * @return {*} 
 */
export default function Details({item, fieldMappings}:DetailsProps) {
  

    const fieldValues = UseFieldValues(item, fieldMappings);
    const settings = useSettings();

    if(!settings) {
      return <div> <Spinner/> </div>
    }

    const hideLabels = settings?.hide_labels_detail_page == 1 
    const boldFields = settings?.bold_details ? settings?.bold_details.replaceAll(" ", "").split(",") : "" 
    const italicFields = settings?.italic_details ? settings?.italic_details.replaceAll(" ", "").split(",") : ""
  
    
    if (!fieldValues.length) return null;
    
    return (
        <article className="item-details-wrapper">
            <h3 className="section-label">Details</h3>
            <div className="item-details-cont">
                <div className="item-details-inner">
                    {fieldValues.map((el: Veevart.FieldValue, i: number) => (
                        <div key={i} className="field-block">
                             {!hideLabels ? (
                                <span className="field-label">{`${el.label}: `}</span>
                              ) : null}
                             <span className="field">
                                {boldFields &&
                                boldFields.includes(el.fieldPath) &&
                                italicFields &&
                                italicFields.includes(el.fieldPath) ? (
                                  <i>
                                    <b>{el.value}</b>
                                  </i>
                                ) : boldFields && boldFields.includes(el.fieldPath) ? (
                                  <b>{el.value}</b>
                                ) : italicFields && italicFields.includes(el.fieldPath) ? (
                                  <i>{el.value}</i>
                                ) : (
                                  el.value
                                )}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </article>
    )
}