import { Link, RouteComponentProps } from 'react-router-dom';
import useSWR from 'swr'
import Loading from '../components/Spinner'
import NoData from '../components/NoData'
import Collection from '../components/Collection'
import Details from '../components/Details';
import Layout from '../components/Layout'
import { useLabels } from '../hooks/UseLabels';
interface RouteParams {
    /**
     * The artist unique name.
     *
     * @type {string}
     * @memberof RouteParams
     */
    artist: string
}

interface ArtistProps extends RouteComponentProps<RouteParams> { }

/**
 * It renders the artist page.
 *
 * @export
 * @param {ArtistProps} props
 * @return {*} 
 */
export default function Artist({ match }: ArtistProps) {

    const { labels } = useLabels();
    const { data, error }: { data?: Veevart.Artist, error?: any } = useSWR([`/veevart/v1/Artist/record/${match.params.artist}`])

    /** It render a UI message when somethin went wrong in the API Call */
    if (error) return <NoData title={labels.No_results_found} message={labels.Error_Loading_PDF} />

    /** It render a UI loading when change the page/fetch data. */
    if (!data) return <Loading />

    /** It render a UI no found when the artist is not exist */
    if (data.status === 404) {
        return (
            <Layout>
                <NoData title={labels.No_results_found} message={labels.It_seems_we_can_t_find_any_results_based_on_your_search} />
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="item-page-wrapper-2 artist-page-wrapper">
                <Link className="link-back-home" to="/"><span>{labels.Back_to_Home}</span></Link>
                <div className="blocks-cont">
                    <section className="item-page-block left">
                        <figure className="artist-image-wrapper">
                            <img className="artist-image" alt={data.Name} src={data.artist_photo} />
                        </figure>
                    </section>

                    <section className="item-page-block right">
                        <div className="item-info-block">
                            <TitleContent data={data} />
                            <div className="item-info-block">
                                <div className="item-info-cont">
                                    <Details item={data} fieldMappings={data.field_mappings} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                {
                    data.Biography &&
                    <section className="blocks-artist">
                        <div className="block-left-artist">{labels.Biography}</div>
                        <div className="block-right-artist">
                            <div className="block-right-artist-inner">
                                <div className="artist-name-wrapper">
                                    <div className="artist-biography-wrapper artist-page-biography-wrapper">
                                        <p className="artist-biography">{data.Biography}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <section className="related-artwork">
                    <div className="related-artworks-header">
                        <div className="related-artwork-label">{labels.Artworks_by}<span className="related-artist-name">{data.Name}</span></div>
                    </div>

                    <div className="related-cards-wrapper">
                        <Collection artist={data.id} pageSize={10} />
                    </div>
                </section>
            </div>
        </Layout>
    )
}

/**
 * It renders the artist title.
 *
 * @param {{ data: Veevart.Artist }} props
 * @return {*} 
 */
function TitleContent({ data }: { data: Veevart.Artist }) {
    return (
        <div className="item-title-cont">
            <div className="artist-name-wrapper">
                <h2 className="artist-name">
                    {data.Name}
                </h2>
                {
                    data.birth_year !== null &&
                    (<span className="year-of-birth">{`, b.${data.birth_year}`}</span>)
                }
            </div>
        </div>
    )
}

