import Pagination from 'rc-pagination';
import Cards from './Cards';
import useSWR from 'swr';
import Loading from './Spinner';
import NoData from './NoData';
import { useState, useEffect } from 'react';
import { useLabels } from '../hooks/UseLabels';
import { useHistory, useLocation } from 'react-router-dom';
import { CollectionType } from '../common';
import { filterFieldset } from '../pages/Artwork';

type CollectionProps = {
    /**
     * The total max. items to display for each page.
     *
     * @type {number}
     */
    pageSize: number;

    /**
     * the artist to filter the artworks.
     *
     * @type {string}
     */
    artist?: string;

    /**
     * the exhibition to filter the artworks.
     *
     * @type {string}
     */
    exhibition?: string;

    /**
     * How the artworks should display to the final user.
     *
     * @type {string}
     */
    sortValue?: string;

    /**
     * The search value to filter the artworks.
     *
     * @type {string}
     */
    search?: string;
    /**
     * Fields with values used for filtering and searching artworks.
     *
     * @type {VeevartCollection.Filters}
     */
    filters?: VeevartCollection.Filters;

    /**
     * The Collection type e.g. "Artwork"
     *
     * @type {CollectionType | undefined}
     */
    collectionType?: CollectionType | undefined;
};
/**
 * Render a collection of artworks.
 *
 * @export
 * @param {CollectionProps}
 * @return {*}
 */
export default function Collection({
    pageSize,
    exhibition,
    artist,
    sortValue,
    search,
    filters,
    collectionType,
}: CollectionProps) {
    /**
     * The page to fetch in API
     */
    const [offset, setOffset] = useState(0);
    /**
     * The current page selected
     */
    const [pageIndex, setPageIndex] = useState(1);

    /**
     * Loading to delay the view when the page change
     */
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageIndexSearch = parseInt(searchParams.get('pageIndex') || '');

    // The application labels
    const { labels } = useLabels();

    const { data, error }: { data?: Veevart.Artworks; error?: any } = useSWR(() => {
        let searchOffset = parseInt(searchParams.get('offset') || '');

        if (isNaN(searchOffset)) {
            searchOffset = offset;
        }

        if (!collectionType) {
            collectionType = CollectionType.Artwork;
        }

        let url = `/veevart/v1/${collectionType}`;
        let query = `?offset=${searchOffset}&limit=${pageSize}`;

        if (artist) {
            query += `&artist=${artist}`;
        }

        if (exhibition) {
            query += `&exhibition=${exhibition}`;
        }

        if (search) {
            query += `&search=${encodeURIComponent(search)}`;
        }

        if (sortValue) {
            const [sort, orderBy] = sortValue.split(';');
            query += `&sort=${sort}`;
            if (orderBy) {
                query += `&order_by=${orderBy}`;
            }
        }

        const searchFilters: { [key: string]: string[] } = {};
        if (collectionType === CollectionType.Artwork && filters && Object.keys(filters).length) {
            for (const key in filters) {
                if (Object.prototype.hasOwnProperty.call(filters, key)) {
                    const element = filters[key];
                    searchFilters[key] = element.values;
                }
            }
        }

        if (Object.keys(searchFilters).length) {
            return [url + '/filter' + query, 'POST', JSON.stringify(searchFilters)];
        } else {
            return [url + query];
        }
    });

    /**
     * It set the new offets to call the API and update the next page.
     *
     * @param {number} nextPage The next collection page to render.
     */
    const handleChange = (nextPage: number) => {
        const newOffset = (nextPage - 1) * pageSize;
        setOffset(newOffset);
        setPageIndex(nextPage);
        history.push({ search: 'offset=' + newOffset.toString() + '&pageIndex=' + nextPage.toString() });
        setLoading(true);
    };

    /** It scroll to top the page while is showing the loading and remove catche image. */
    useEffect(() => {
        if (loading === true) {
            window.scrollTo(0, 0);
        }

        let waitLoading = setTimeout(() => setLoading(false), 100);

        return () => clearTimeout(waitLoading);
    }, [loading]);

    /** Renders a UI message when something went wrong in the API Call. */
    if (error) return <NoData title={labels.No_results_found} message={labels.Error_Loading_PDF} />;

    /** It renders a UI loading when changing the page/fetch data. */
    if (!data || loading) return <Loading />;

    /** It renders a UI message when no artworks will found for the filter applied to it. */
    if (!data.results?.length) {
        return (
            <NoData
                title={labels.No_results_found}
                message={labels.It_seems_we_can_t_find_any_results_based_on_your_search}
            />
        );
    }

    const fieldMapping = filterFieldset('Detail_Fields_Card_Collection', data.field_mappings);

    /** It renders a UI list of artworks when there is a result. */
    return (
        <>
            <Cards records={data.results} fieldMappings={fieldMapping || []} collectionType={collectionType} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {pageSize < data.count && (
                    <Pagination
                        pageSize={pageSize}
                        current={isNaN(pageIndexSearch) ? pageIndex : pageIndexSearch}
                        total={data.count}
                        onChange={handleChange}
                    />
                )}
            </div>
        </>
    );
}
