
import Collections from './Collections'
import './Home.scss'
import { withRouter } from 'react-router-dom';
import { useSettings } from '../hooks/UseSetting';
import Artworks from './Artworks';

/**
 * It render a collection of artworks that can be filter by it artist, objet medium and year. 
 *
 * @export
 * @param {HomeProps} props
 * @return {JSX.Element}  
 */
export function Home() {
    const settings = useSettings();
    if (settings?.enable_collection_section && settings?.collection_home_page_template === 'Collection') {
        return <Collections/>
    } else {
        return <Artworks/>
    }

}

export default withRouter(Home);