import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { CollectionType, GridTemplate } from '../common';
import { useSettings } from '../hooks/UseSetting';
import Card from './Card'
import './Cards.scss'
import Spinner from './Spinner';

/** The mansony setting to display in each media screen*/
 

type CardsProps = {
    /**
     * List of artwork to render.
     *
     * @type {Veevart.Artwork[]}
     */
    records: Veevart.Artwork[] | Veevart.Artist[];
    /**
     * The artwork mapping fields to display in the render.
     *
     * @type {Veevart.FieldSetMember[]}
     */
    fieldMappings: Veevart.FieldSetMember[];

    /**
     * The Collection type e.g. "Artwork"
     *
     * @type {CollectionType | undefined}
     */
     collectionType?: CollectionType | undefined;
}

/**
 * Render a list the collection of artwork and add a lazy load to each item. 
 *
 * @export
 * @param {CardsProps} 
 * @return {JSX.Element} 
 */
export default function Cards({ records, fieldMappings, collectionType }: CardsProps) {

    const settings = useSettings();
    let settingsMasonry;
    
    if (collectionType === CollectionType.Exhibition) {
        settingsMasonry = {
            539: 1,
            600: 2,
            790: 2,
            1024: 2,
        }
    } else {
        settingsMasonry = {
            539: 1,
            600: 2,
            790: 3,
            1024: 4,
        }
    }

    if(!settings) {
      return <div>
        <Spinner/>
      </div>
    }

    if (settings?.collection_grid_template === GridTemplate.GeometricLayout || collectionType !== CollectionType.Artwork) {
        return (
            <div className={`items-cards-wrapper geometric_layout ${collectionType}`}>
                {records.map((record, index) => (
              <Card
                key={(record.Id || record.id) + index}
                record={record}
                collectionType={collectionType}
                fieldMappings={fieldMappings}
                settingsCollection={settings}
              />
            ))}
            </div>
        )
    } else{
        return (
            <ResponsiveMasonry
                columnsCountBreakPoints={settingsMasonry}>
                <Masonry className={`masonry-wrapper`}>
                {records.map((record, index) => (
                  <Card
                    key={(record.Id || record.id) + index}
                    record={record}
                    collectionType={collectionType}
                    fieldMappings={fieldMappings}
                    settingsCollection={settings}
                  />
                ))}
                </Masonry>
            </ResponsiveMasonry>
    
    
        )
    }
    
}