import {Link} from 'react-router-dom'
import { useLabels } from '../hooks/UseLabels'

type LogoProps = {
    /**
     * The organization logo link
     *
     * @type {string}
     */
    url?: string,
    /**
     * The organization name
     *
     * @type {string}
     */
    name?: string
}

/**
 * It renders the header logo
 *
 * @export
 * @param {LogoProps} props
 * @return Return a figure with the organization logo. 
 */
export default function Logo({url,name}: LogoProps) {
    
    // The application labels
    const {labels} = useLabels();
    
    return (
        <div className="logo-block">
            <Link to="/">
                <figure className="logo-wrapper">
                    <img src={url || labels.Internal_Email_Notification_Logo_URL} alt={name}/>
                    { name && <span className="structural-heading">{name}</span>}
                </figure>
            </Link>
        </div>
    )
}