/**
 * Collection Artwork Grid Template 
 */
export enum GridTemplate {
    GeometricLayout='Geometric_Layout',
    MasonryLayout='Masonry_Layout'
}

/**
 * Collection filter template
 */
export enum FilterTemplate {
    SpaceBetween='Space_Between',
    Compact='Compact'
}

/**
 * Collection home page template
 */
export enum HomePageTemplate {
    AllArtworks='All_Artworks',
    Collection='Collection'
}

/**
 * Collection Items types.
 */
export enum CollectionType {
    Artist='Artist',
    Artwork='Artwork',
    Exhibition='Exhibition'
}