import { Link } from 'react-router-dom';
import Layout from '../components/Layout'
import './NoFound.scss'

/**
 * Render a 404 page with a link to return to home.
 *
 * @export
 * @return {*} 
 */
export default function NoFound() {
  return (
    <Layout>
      <section className="error-main-cont">
        <div className="error-message-wrapper">
          <h1>Error</h1>
          <h1 className="error-message">404</h1>
          <h3>Page not found</h3>
          <p>We are working to improve our site and some pages have recently moved. You can return <Link to="/">our homepage</Link> or use the navigation and search above.</p>
        </div>
      </section>
    </Layout>
  )
}
