import Logo from './Logo'
import Search from './Search'
import './Header.scss'
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import { useSettings } from '../hooks/UseSetting';
import { useLabels } from '../hooks/UseLabels';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { HomePageTemplate } from '../common';

interface RouteParams {
    /**
     * The search value to searching the artworks.
     *
     * @type {string}
     * @memberof RouteParams
     */
    search_string: string
}

interface HeaderProps extends RouteComponentProps<RouteParams> { }

/**
 * It renders the heade of the application. 
 *
 * @export
 * @param {HeaderProps} props
 * @return Render the collection Header with it logo and search bar
 */
export function Header({ match }: HeaderProps) {

    // Application settings
    const settings = useSettings();
    const { labels } = useLabels();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
    const template = settings?.collection_home_page_template || HomePageTemplate.AllArtworks;
    const homePageTemplate: { [key: string]: { label: string, to: string } } = {
        [HomePageTemplate.Collection]: { label: labels.All_Artworks, to: '/art/artworks' },
        [HomePageTemplate.AllArtworks]: { label: labels.Collection, to: '/art/collection' }
    };

    return (
        <header className='main-header'>
            <div className='main-header-inner'>
                <div className='header-block-left'>
                    {
                        isTabletOrMobile
                            ? <button onClick={() => (setShowSearchBar(!showSearchBar))} className={`icon-search-responsive ${showSearchBar}`} />
                            : <Logo url={settings?.logo} name={settings?.name} />
                    }

                    {
                        settings?.enable_collection_section
                            ? (
                                <div className={`header-menu ${isTabletOrMobile ? showMenu : ''}`}>
                                    <NavLink to={'/'} exact activeClassName="selected">{labels.Home}</NavLink>
                                    {
                                        homePageTemplate[template] &&
                                        <NavLink exact activeClassName="selected" to={homePageTemplate[template].to}>
                                            {homePageTemplate[template].label}
                                        </NavLink>
                                    }
                                </div>
                            ) : null
                    }
                </div>
                {
                    isTabletOrMobile && (
                        <div className='header-block-center'>
                            <Logo url={settings?.logo} name={settings?.name} />
                        </div>
                    )
                }
                <div className='header-block-right'>
                    <Search defaultVal={match.params.search_string} showSearchBarResponsive={showSearchBar} />
                    {
                        (isTabletOrMobile &&  settings?.enable_collection_section)
                            ? <button onClick={() => (setShowMenu(!showMenu))} className={`icon-menu-responsive ${showMenu}`} />
                            : null
                    }
                </div>
            </div>
        </header>
    );
}

export default withRouter(Header);
