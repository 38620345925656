import { Link, RouteComponentProps } from 'react-router-dom';
import Loading from '../components/Spinner';
import NoData from '../components/NoData';
import Gallery from '../components/Gallery';
import useSWR from 'swr';
import './Artwork.scss';
import Details from '../components/Details';
import Layout from '../components/Layout';
import { useLabels } from '../hooks/UseLabels';

interface RouteParams {
    /**
     * The artwork unique name.
     *
     * @type {string}
     * @memberof RouteParams
     */
    artwork: string;
}

interface ArtworkProps extends RouteComponentProps<RouteParams> {}

/**
 * It renders the artwork page.
 *
 * @export
 * @param {ArtworkProps}
 * @return {*}
 */
export default function Artwork({ match }: ArtworkProps) {
    const { labels } = useLabels();
    const { data, error }: { data?: Veevart.Artwork; error?: any } = useSWR([
        `/veevart/v1/Artwork/record/${match.params.artwork}`,
    ]);

    /** It render a UI message when somethin went wrong in the API Call */
    if (error) return <NoData title={labels.No_results_found} message={labels.Error_Loading_PDF} />;

    /** It render a UI loading when change the page/fetch data. */
    if (!data) return <Loading />;

    /** It render a UI no found when the artwork is not exist */
    if (data.status === 404) {
        return (
            <Layout>
                <NoData
                    title={labels.No_results_found}
                    message={labels.It_seems_we_can_t_find_any_results_based_on_your_search}
                />
            </Layout>
        );
    }

    /** Calls the function to filter the field set */
    const fieldMapping = filterFieldset('Website_Integration', data.field_mappings);

    return (
        <Layout>
            <div className="item-page-wrapper-2">
                <Link className="link-back-home" to="/">
                    <span>{labels.Back_to_Home}</span>
                </Link>
                <div className="blocks-cont">
                    {data.files?.length ? (
                        <section className="item-page-block left">
                            <Gallery files={data.files} />
                        </section>
                    ) : null}
                    <section className={'item-page-block right' + (data?.files?.length ? '' : ' full ')}>
                        <div className="item-info-block">
                            <TitleContent data={data} />
                            <div className="item-info-block">
                                <div className="item-info-cont">
                                    <Details item={data} fieldMappings={fieldMapping} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {data.Description && (
                    <div className="main-wrapper-description">
                        <InformationContent data={data} />
                    </div>
                )}
                <Artists data={data} />
            </div>
        </Layout>
    );
}

/**
 * Filters the field set depending if it's the home page or the detail page
 * @param fieldsetName // Name of the field set
 * @param fielsetMember  // Fieldset that contains both the home and the detail page fieldset
 * @returns fieldMapping // Fieldset filtered with the fieldset of either the home page or the detail page
 */
export function filterFieldset(
    fieldsetName: string,
    fielsetMember?: Veevart.FieldSetMember[]
): Veevart.FieldSetMember[] | undefined {
    const fieldMapping = fielsetMember?.filter((fieldMapping) => {
        return fieldMapping.set_name === fieldsetName;
    });
    return fieldMapping;
}

/**
 * It renders the artwork titles.
 *
 * @param {{ data: Veevart.Artwork }} props The artwork information.
 * @return {*}
 */
function TitleContent({ data }: { data: Veevart.Artwork }) {
    return (
        <div className="item-title-cont">
            <h2 className="item-title">{data.Name}</h2>
            <div className="other-info">
                <span className="artist-name">
                    <Link to={`/artists/${data.artist_unique_name}`}>{data.artist_name}</Link>
                </span>
                <br />
                {data.artist_birth_death_dates !== null && (
                    <span>{data.artist_birth_death_dates ? ` ${data.artist_birth_death_dates}` : ''}</span>
                )}
                {(data.artist_Nationality && data.artist_birth_death_dates) !== null && (
                    <span>{data.artist_birth_death_dates ? ',' : ''}</span>
                )}
                {data.artist_Nationality !== null && (
                    <span>{data.artist_Nationality ? `  ${data.artist_Nationality}` : ''}</span>
                )}
            </div>
        </div>
    );
}

/**
 * It renders the artworks information.
 *
 * @param {{ data: Veevart.Artwork }} props The artworks information.
 * @return {*}
 */
function InformationContent({ data }: { data: Veevart.Artwork }) {
    return (
        <div className="item-info-cont">
            <article className="item-details-wrapper">
                <h3 className="section-label">Description</h3>
                <div className="item-details-cont">
                    <div className="item-details-inner">
                        <div className="description-wrapper">
                            <p className="description">{data.Description}</p>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

/**
 * It renders the artists information.
 *
 * @param {{ data: Veevart.Artwork }} props the artwork information.
 * @return {*}
 */
function Artists({ data }: { data: Veevart.Artwork }) {
    return (
        <section className="blocks-artist">
            <div className="block-left-artist">About the Artist</div>
            <div className="block-right-artist">
                <div className="block-right-artist-inner">
                    <div className="artist-name-wrapper">
                        <h2 className="artist-name">
                            <Link to={`/artists/${data.artist_unique_name}`}>{data.artist_name}</Link>
                        </h2>
                        {data.artist_birth_year != null && (
                            <span className="year-of-birth">{`, b.${data.artist_birth_year}`}</span>
                        )}
                    </div>

                    <div className="artist-biography-wrapper">
                        <p className="artist-biography">{data.artist_biography}</p>
                    </div>
                    <Link className="link-view-artist" to={`/artists/${data.artist_unique_name}`}>
                        View Artist
                    </Link>
                </div>
            </div>
        </section>
    );
}
